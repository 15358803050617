import { graphql } from "gatsby";
import React from "react";

import CollapseTags from "../components/collapse-tags/collapse-tags";
import { FullWidthColumnWrapper } from "../components/confines-wrappers";
import FiltersApplied from "../components/filters-applied/filters-applied";
import Footer from "../components/footer";
import GetInTouch from "../components/get-in-touch";
import { Gradient } from "../components/gradients";
import Header from "../components/header";
import HelmetWrapper from "../components/helmet-wrapper";
import { breadCrumbSchema } from "../components/html-schema-snippets";
import {
  IBreadCrumb,
  IBreadCrumbSchema
} from "../components/html-schema-snippets/breadcrumb-schema";
import Layout from "../components/layout";
import NoItemsFound from "../components/no-items-found/no-items-found";
import PageTop from "../components/page-top";
import Pagination from "../components/pagination";
import Tags from "../components/project-tags";
import ProjectsGrid from "../components/projects-grid";
import Text from "../components/text";
import {
  IProjectData,
  PageTopCustom,
  ProjectsContainer
} from "../pages/projects";

export interface IAllTaxonomyTermProject {
  edges: ITaxonomyTermProject[];
}

interface ITaxonomyTermProject {
  name: string;
  relationships: {
    node__project: IProjectData[] | null;
  };
}

interface ITaxonomyTermProject {
  node: {
    name: string;
    path: {
      alias: string;
    };
  };
}

interface IPageContext {
  skip: number;
  limit: number;
  current: number;
  name: string;
  slug: string;
  total: number;
}

interface INodeData {
  node: IProjectData;
}

interface IProjectTagsProps {
  allNodeProject: {
    edges: INodeData[];
  } | null;
  allTaxonomyTermProjects: IAllTaxonomyTermProject;
}

/**
 * Projects tagged page
 */
const ProjectTags = ({
  data,
  pageContext
}: {
  data: IProjectTagsProps;
  pageContext: IPageContext;
}) => {
  const paginationPage = pageContext.current + 1;

  const breadcrumbs: IBreadCrumb[] = [
    {
      name: "Projects",
      path: "/projects"
    },
    {
      name: pageContext.name,
      path: pageContext.slug
    }
  ];

  let projectNodes: INodeData[] = [];
  let projects: IProjectData[] = [];
  let schemaJson: IBreadCrumbSchema[] = [];

  // If the pagination page value is greater than the 1st page, add a breadcrumb
  if (paginationPage > 1) {
    breadcrumbs.push({
      name: `Page ${paginationPage}`,
      path: `${pageContext.slug}/${paginationPage}`
    });
  }

  // Now set the schema JSON object
  schemaJson = [ breadCrumbSchema(breadcrumbs) ];

  // If there is allNodeProject, set the projectNodes variable
  if (data.allNodeProject) {
    projectNodes = data.allNodeProject.edges;

    projects = projectNodes.map(projectNode => {
      return projectNode.node;
    });
  }

  return (
    <Layout className="projects projects-by-tag">
      <>
        <HelmetWrapper
          title={paginationPage === 1 ?
            `${pageContext.name} Projects` :
            `${pageContext.name} Projects page ${paginationPage}`}
          description={`Specific ${pageContext.name} Projects which form part of our portfolio`}
          updatedAt={projects[ 0 ] ? projects[ 0 ].changed : ""}
          canonicalPath={pageContext.slug}
        >
          <script type="application/ld+json">
            {JSON.stringify(schemaJson)}
          </script>
        </HelmetWrapper>

        <header
          role="banner"
          id="header"
        >
          <Header
            initialLogo
            initialTheme="dark"
          />

          <PageTopCustom id="hero">
            <PageTop
              gradient="darkBlueOrange"
              style={{ color: "white" }}
              height="small"
            >
              <Gradient
                gradient="purpleOrange"
                angle={75}
                options={{ colorStops: [ "80%", "100%" ] }}
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  left: 0,
                  top: 0,
                  opacity: 0.5,
                  zIndex: -1
                }}
              />

              <FullWidthColumnWrapper className="top-content">
                <p className="heading">
                  {`${pageContext.name} projects`}
                </p>

                <Text
                  className="filtered-by"
                  fontSize="small_2"
                  letterSpacing="medium"
                  fontColor="third"
                  lineHeight="normalized"
                  style={{ textTransform: "uppercase" }}
                >
                  Filter by:
                </Text>

                <Tags data={data} />
              </FullWidthColumnWrapper>
            </PageTop>
          </PageTopCustom>
        </header>

        <CollapseTags
          root="/projects"
          rootTerm="Featured"
          tags={data.allTaxonomyTermProjects.edges}
        />

        <aside>
          <FiltersApplied
            filter={pageContext.name}
            clearLink="/projects"
            type="project"
          />
        </aside>

        <main
          id="main-content"
          role="main"
        >
          <h1 className="off-screen">
            {`${pageContext.name} projects`}
          </h1>

          {projects.length === 0 ? (
            <NoItemsFound>
              <p>
                <strong>
                  Oops
                </strong>
                . No projects were found.
              </p>
            </NoItemsFound>
          ) : (
            <ProjectsContainer>
              <div className="projects-grid-container">
                <ProjectsGrid projects={projects} />
              </div>
            </ProjectsContainer>
          )}
        </main>

        <aside>
          <FullWidthColumnWrapper>
            <Pagination
              current={pageContext.current}
              total={pageContext.total}
              limit={pageContext.limit}
              slug={pageContext.slug}
            />
          </FullWidthColumnWrapper>
        </aside>

        <footer role="contentinfo">
          <GetInTouch />

          <Footer />
        </footer>
      </>
    </Layout>
  );
};

export default ProjectTags;

export const projectTagsQuery = graphql`
  query($slug: [String], $skip: Int = 0, $limit: Int = 10, $tagLimit: Int = 9) {
    allNodeProject(
      limit: $limit
      skip: $skip
      filter: {
        relationships: {
          field_project_tags: { elemMatch: { path: { alias: { in: $slug } } } }
        }
      }
      sort: { fields: created, order: DESC }
    ) {
      edges {
        node {
          ...getProjectData
        }
      }
    }
    ...getProjectTags
  }
`;

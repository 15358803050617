import * as React from "react";

import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import Icon from "../icon";
import Link from "../link";

interface IProps {
  current: number;
  total: number;
  limit: number;
  slug: string;
}

const PaginationWrapper = styled.nav`
  padding-top: 0 !important;

  .pagination-wrapper {
    display: flex;
    justify-content: center;

    ul,
    .more-shortcut {
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_2"
  })};
      font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
    }

    ul li a,
    .more-shortcut a {
      color: ${({ theme }) => colourHelper({
    theme,
    variant: "black"
  })};
      fill: ${({ theme }) => colourHelper({
    theme,
    variant: "black"
  })};

      .icon {
        transition: none;
      }

      &:hover {
        color: ${({ theme }) => colourHelper({
    theme,
    variant: "brandColor"
  })};
        fill: ${({ theme }) => colourHelper({
    theme,
    variant: "brandColor"
  })};
      }
    }

    ul {
      display: none;
      flex-direction: row;
      padding: 0;
      list-style: none;

      li {
        padding: 0 0.8rem 0 0;

        a {
          display: block;
          width: 2.4rem;
          text-align: center;
          border-bottom: 0.2rem solid transparent;

          &.active {
            color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
            border-bottom-color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
          }
        }
      }
    }

    .more-shortcut {
      padding-top: 0;

      a {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 6.8rem;

        .text,
        .icon {
          display: block;
        }
      }
    }
  }

  @media (min-width: 600px) {
    .pagination-wrapper {
      justify-content: space-between;

      ul {
        display: flex;
      }
    }
  }
`;

const Pagination = ({
  current, total, limit, slug
}: IProps) => {
  const pageCount = Math.ceil(total / limit);
  const Pages: number[] = new Array(pageCount).fill(null);
  const next = current + 1;
  const groupSize = 5;
  const groupEdgeSize = Math.floor(groupSize / 2);
  let groupIdxStart = current - groupEdgeSize;
  let groupIdxEnd = current + groupEdgeSize;

  // Make sure that the groupIdxStart and groupIdxEnd indexes remain at the bottom
  // or the top of the possible spectrum
  if (groupIdxStart <= 0) {
    groupIdxStart = groupSize - groupSize;
    groupIdxEnd = groupSize - 1;
  } else if (groupIdxEnd >= pageCount - 1) {
    groupIdxStart = pageCount - groupSize;
    groupIdxEnd = pageCount - 1;
  }

  // Return null if total is 0
  if (total === 0 || pageCount <= 1) {
    return null;
  }

  return (
    <PaginationWrapper
      className="pagination-container content-container"
      role="navigation"
      aria-label="Pagination navigation"
    >
      <div className="pagination-wrapper">
        <ul>
          {Pages.map((_, i) => {
            const href = i === 0 ? slug : `${slug}/${i + 1}`;
            const pageNo = i + 1;

            // For the first page, which is not active
            if (i === 0 && current >= groupSize - groupEdgeSize) {
              return (
                <li key={i}>
                  <Link
                    type="none"
                    activeClassName="active"
                    href={href}
                    ariaLabel={`Goto page ${pageNo}`}
                  >
                    <>
                      {pageNo}
                      ...
                    </>
                  </Link>
                </li>
              );
            }

            // // For the last page, which is not active
            // if (
            //   i === pageCount - 1 &&
            //   current < pageCount - (groupSize - 1)
            // ) {
            //   return (
            //     <li key={i}>
            //       <Link
            //         type="none"
            //         activeClassName="active"
            //         href={href}
            //         ariaLabel={`Goto page ${pageNo}`}
            //       >
            //         <>...{pageNo}</>
            //       </Link>
            //     </li>
            //   );
            //

            if (i >= groupIdxStart && i <= groupIdxEnd) {
              return (
                <li key={i}>
                  <Link
                    type="none"
                    activeClassName="active"
                    href={href}
                    rel={current === i ? "canonical" : undefined}
                    ariaLabel={`Goto page ${pageNo}`}
                  >
                    <>
                      {pageNo}
                    </>
                  </Link>
                </li>
              );
            }

            return;
          })}
        </ul>

        {next < pageCount && (
          <p className="more-shortcut">
            <Link
              type="none"
              href={`${slug}/${next + 1}`}
              rel="next"
              ariaLabel={`Goto the next page, page ${next + 1}`}
            >
              <>
                <span className="text">
                  <span className="off-screen">
                    Click here to view
                    {" "}
                  </span>
                  More

                  <span className="off-screen">
                    {" "}
                    (next page).
                  </span>
                </span>

                <Icon
                  className="icon"
                  icon="arrowRight"
                  size={1.6}
                />
              </>
            </Link>
          </p>
        )}
      </div>
    </PaginationWrapper>
  );
};

export default Pagination;
